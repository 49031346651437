import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import { Link } from 'gatsby'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const Team: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <article className="post">
          <div className="p-5 text-center">
            <h1 className="text-uppercase">Need help ?</h1>
            <p className="lead">Stay in touch with your e-commerce team</p>
          </div>
          <div className="row align-items-end">
            <div className="col-md-6 p-4">
              <div className="team-card mb-3">
                <div className="team-profile mb-2">
                  <div className="team-bio">
                    <small>
                      Managing and coordinating our website and e-commerce
                      projects worldwide with a clear mission to bring clients’
                      digital experience to the next level.
                    </small>
                  </div>
                  <img
                    src={'/img/christophe-jeanneret.jpg'}
                    className="team-portrait shadow-lg"
                  />
                </div>
                <div className="team-member">
                  <h3><a href="mailto:christophe.jeanneret@longines.com" className="stretched-link">Christophe Jeanneret</a></h3>
                  <p>Head of International E-commerce</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-4">
              <div className="team-card mb-3">
                <div className="team-profile mb-2">
                  <div className="team-bio">
                    <small>
                      Develop Longines’ corporate e-stores network as well as
                      boost and supervise corporate and wholesale online sales
                      activities.
                    </small>
                  </div>
                  <img
                    src={'/img/blaise-virchaux.jpg'}
                    className="team-portrait shadow-lg"
                  />
                </div>
                <div className="team-member">
                  <h3><a href="mailto:blaise.virchaux@longines.com" className="stretched-link">Blaise Virchaux</a></h3>
                  <p>International E-commerce Manager</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-end">
            <div className="col-md-6 p-4">
              <div className="team-card mb-3">
                <div className="team-profile mb-2">
                  <div className="team-bio">
                    <small>
                      Pilot the rollout of new corporate e-stores and support
                      subsidiaries in their corporate online sales activities.
                    </small>
                  </div>
                  <img
                    src={'/img/marcial-rodriguez.jpg'}
                    className="team-portrait shadow-lg"
                  />
                </div>
                <div className="team-member">
                  <h3><a href="mailto:marcial.rodriguez@longines.com" className="stretched-link">Marcial Rodriguez</a></h3>
                  <p>International Corporate E-commerce Project Leader</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-4">
              <div className="team-card">
                <div className="team-profile mb-2">
                  <div className="team-bio">
                    <small>
                      Lead the development and supervision of Longines’
                      wholesale online sales activities.
                    </small>
                  </div>
                  <img
                    src={'/img/kutay-karaagac.jpg'}
                    className="team-portrait shadow-lg"
                  />
                </div>
                <div className="team-member">
                  <h3><a href="mailto:kutay.karaagac@longines.com" className="stretched-link">Kutay Karaagaç</a></h3>
                  <p>International Wholesale E-commerce Project Leader</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-end">
            <div className="col-md-6 p-4">
              <div className="team-card mb-3">
                <div className="team-profile mb-2">
                  <div className="team-bio">
                    <small>
                      Coordinate and develop the operational e-commerce
                      activities with the different departments, markets and
                      partners of the company.
                    </small>
                  </div>
                  <img
                    src={'/img/simon-guerne.jpg'}
                    className="team-portrait shadow-lg"
                  />
                </div>
                <div className="team-member">
                  <h3><a href="mailto:simon.guerne@longines.com" className="stretched-link">Simon Guerne</a></h3>
                  <p>International E-commerce Operations Coordinator</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-4">
              <div className="team-card">
                <div className="team-profile mb-2">
                  <div className="team-bio">
                    <small>
                      Propose, lead and boost Longines Digital projects and
                      collaborate with other departments, partners and the
                      digital team to achieve the goals.
                    </small>
                  </div>
                  <img
                    src={'/img/steven-valente.jpg'}
                    className="team-portrait shadow-lg"
                  />
                </div>
                <div className="team-member">
                  <h3><a href="mailto:steven.valente@longines.com" className="stretched-link">Steven Valente</a></h3>
                  <p>International Digital Marketing Manager</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-end">
            <div className="col-md-6 p-4">
              <div className="team-card">
                <div className="team-profile mb-2">
                  <div className="team-bio">
                    <small>
                      Propose and develop Digital projects for Longines with
                      many departments and partners as well as provide support
                      to the Longines subsidiaries around the world.
                    </small>
                  </div>
                  <img
                    src={'/img/dani-heitor.jpg'}
                    className="team-portrait shadow-lg"
                  />
                </div>
                <div className="team-member">
                  <h3><a href="mailto:dani.heitor@longines.com" className="stretched-link">Dani Heitor</a></h3>
                  <p>International Digital Marketing Project Leader</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-4">
              <div className="team-card mb-3">
                <div className="team-profile mb-2">
                  <div className="team-bio">
                    <small>
                      Propose and develop Digital projects for Longines with
                      many departments and partners as well as provide support
                      to the Longines subsidiaries around the world.
                    </small>
                  </div>
                  <img
                    src={'/img/nils-lehmann.jpg'}
                    className="team-portrait shadow-lg"
                  />
                </div>
                <div className="team-member">
                  <h3><a href="mailto:nils.lehmann@longines.com" className="stretched-link">Nils Lehmann</a></h3>
                  <p>International Digital Marketing Project Leader</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-end">
            <div className="col-md-6 p-4">
              <div className="team-card mb-3">
                <div className="team-profile mb-2">
                  <div className="team-bio">
                    <small>
                      Propose and develop the Digital and e-commerce projects
                      for Longines with a focus on integration, security and IT
                      components.
                    </small>
                  </div>
                  <img
                    src={'/img/alexandre-fresco.jpg'}
                    className="team-portrait shadow-lg"
                  />
                </div>
                <div className="team-member">
                  <h3><a href="mailto:alexandre.fresco@longines.com" className="stretched-link">Alexandre Fresco</a></h3>
                  <p>Senior Digital Project Leader</p>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </Layout>
  )
}

export default Team

export const pageQuery = graphql`
  query TeamQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
